import Cactus from '@ex-management-systems/cactus-ui'
import { routes } from './router'
import './style.css'

new Cactus({
    name: import.meta.env.CACTUS_APP_NAME,
    logo: '/images/logo_dark.svg',
    routes: routes,
    api: {
        root: {
            url: import.meta.env.CACTUS_ROOT_API
        },
        maps: {
            url: import.meta.env.CACTUS_MAPS_API
        }
    },
    security: {
        recaptcha: {
            siteKey: import.meta.env.CACTUS_RECAPTCHA_SITE_KEY
        }
    },
    aws: {
        cloudfront: {
            url: import.meta.env.CACTUS_CLOUDFRONT
        }
    }
}).app()